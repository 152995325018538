type PageContent = {
    slug: string;
    title: string;
    content: string;
};

export const content: Array<PageContent> = [
    {
        slug: 'villkor',
        title: 'Villkor.',
        content: `
Endast företag får beställa tjänster enligt dessa villkor.   
<br />
<br />Tjänstens omfattning:
<br />
<br />Vi erbjuder budtjänster för transport av gods och leveranser enligt överenskommelse.
<br />
<br />Leveranserna kan inkludera dokument, paket, varor eller andra ägodelar.
<br />
<br /> Ansvar och ersättning:
<br />
<br />Vi ansvarar för godsets säkerhet under transporten.
<br />
<br />Eventuella skador på gods som orsakats av Bzzt under transporten ersätts upp till ett värde av 500 kr. Reklamation av eventuella skador måste göras direkt i samband med mottagandet av godset.
<br />
<br />Vi kan inte hållas ansvariga för skador som överstiger detta värde. Endast direkta skador på godset ersätts.
<br />
<br /> Förpackning och hantering:
<br />
<br />Vi förväntar oss att gods som ska transporteras är rätt förpackat för att minimera risken för skador under transporten.
<br />
<br />Vi kan inte hållas ansvariga för skador som uppstår på grund av felaktig förpackning eller hantering av godset innan vi har tagit emot det.
<br />
<br />I det fall att godsets verkliga storlek inte överensstämmer med den vid beställning uppgivna storleken, och detta medför merkostnader för oss, har vi rätt att debitera kunden för dessa kostnader. 
<br />
<br />Kunden ansvarar för att den leveransinformation och annan data som meddelas oss inför transporten är korrekt. Bzzt kommer att använda sådan information för utförande av transporten, statistikändamål och datasamling. 
<br />
<br />Leveranstider:
<br />
<br />Vi strävar efter att leverera gods i tid enligt överenskommelse/beställning. 
<br />
<br />Vid försening ersätter vi endast faktiska direkta merkostnader upp till ett belopp på 500 kr, och endast om förseningen är väsentlig.
<br />
<br />Eventuella förseningar som uppstår på grund av oförutsedda omständigheter, trafik eller andra hinder kan inträffa och vi kan inte hållas ansvariga för sådana förseningar.
<br />
<br /> Betalning:
<br />
<br />Betalning för våra tjänster ska ske enligt beställning och genom faktura. Priset för tjänsten bestäms av vald transportprodukt vid beställning, i kombination med tidigare överenskommen prislista. 
<br />
<br />Vi tillämpar en betalningstid på 20 dagar och fakturerar var 14:e dag.
<br />
<br />Vi tar ut en fakturaavgift på 29 kr för varje faktura.
<br />
<br />Vid eventuell försenad betalning kan vi ta ut dröjsmålsränta och påminnelseavgift enligt gällande lagstiftning.
<br />
<br /> Behandling av personuppgifter och GDPR:
<br />
<br />Se vår personuppgiftspolicy <a href="https://www.bzzt.se/personuppgiftspolicy">här</a>
<br />
<br />
<br />
<br />För det fall ni har särskilda önskemål avseende våra tjänster kan särskilt avtal träffas vid behov. Vid eventuella frågor eller för att diskutera alternativa villkor, vänligen kontakta oss. 
`,
    },
    {
        slug: 'personuppgiftspolicy',
        title: 'Personuppgiftspolicy',
        content: `
För att kunna erbjuda dig schyssta och klimatsmarta leveranser behöver vi (Bzzt, alltså) samla in och behandla en del personuppgifter om dig. Vi kommer främst att samla in och behandla personuppgifter om dig i samband med att du beställer leverans av mat, varor och andra produkter (“leveranser”) från någon av våra samarbetspartners (“partner”). 
<br />
<br />Du har full rätt att ha koll på hur dina personuppgifter används. Den här personuppgiftspolicyn förklarar därför hur vi samlar in och behandlar personuppgifter om dig i samband med att du utnyttjar våra tjänster, besöker www.bzzt.se (”hemsidan”) eller kontaktar oss. Den beskriver också dina rättigheter och hur du kan göra dina rättigheter gällande.
<br />
<br />Bzzt AB publ (556989-1020) är personuppgiftsansvarig för den behandling av dina personuppgifter som beskrivs här. Du kan när som helst kontakta oss om du har frågor kring dina personuppgifter eller denna personuppgiftspolicy, genom att maila oss på support@bzzt.se.
<br />
<br />Dataskyddsombud.
<br />Vi har utsett ett dataskyddsombud, som hjälper oss att se till så att dina personuppgifter behandlas på ett korrekt och lagligt sätt. Du kan kontakta dataskyddsombudet i alla frågor som rör Bzzt:s behandling av dina personuppgifter genom att maila till dataskydd@bzzt.se.
<br />
<br />Personuppgifter som samlas in om dig. 
<br />
<br />Vi kan samla in och behandla olika typer av personuppgifter om dig när du använder våra tjänster eller kontaktar oss. 
<br />
<br />Hur dina uppgifter samlas in.
<br />
<br />Information som du lämnar till oss. Merparten av alla uppgifter som behandlas inom ramen för hemsidan lämnar du själv till oss, till exempel när du kontaktar oss via hemsidan, mejl eller Facebook. Det är frivilligt att lämna uppgifter till oss. En del uppgifter måste du dock lämna för att vi ska kunna bistå dig på bästa sätt när du söker jobb hos oss eller kontaktar oss i frågor som rör våra tjänster.
<br />
<br />Information som samlas in på andra sätt. Vi kan även inhämta information om dig på andra sätt, detta gäller främst i samband med leveranser. Exempelvis kan vi inhämta information om dig från våra partners, leverantörer och andra pålitliga källor. Vi kan också skapa uppgifter som kan kopplas till dig, till exempel med hjälp av cookies när du använder besöker hemsidan.
<br />
<br />Hur dina uppgifter kommer behandlas.
<br />
<br />Vi använder framförallt dina personuppgifter för att kunna tillhandahålla våra tjänster, kommunicera med dig och för att administrera dina önskemål och ärenden inom ramen för våra tjänster.
<br />
<br />Leveranser.
<br />
<br />I samband med leveranser behandlar vi dina personuppgifter för följande syften med följande lagliga grunder:  
<br />
<br />För att leverera produkter till dig. Dina personuppgifter (bl.a. beställning- och leveransinformation) kommer att behandlas för att vi ska kunna leverera och avlämna de produkter som du har beställt från partnern. I samband med leveranser kan vi därför komma behandla uppgifter om antal beställda produkter, önskad leveransadress och telefonnummer.
<br />
<br />För att fullgöra våra åtaganden gentemot våra partners. Dina personuppgifter kommer att behandlas av oss i samband med leveranser för att vi ska kunna fullgöra våra åtaganden gentemot våra partnerns i enlighet med tillämpliga partneravtal.
<br />
<br />För att ge dig support och kommunicera med dig. Dina personuppgifter (bl.a. namn, mail och telefonnummer) kan användas för att undersöka, svara på och lösa klagomål och problem hänförliga till våra leveranser. Kuriren kan också ibland komma att kontakta dig på ditt mobilnummer i samband med att vi levererar produkter till dig. Detta är nödvändigt för att du ska få dina produkter levererade som avtalat med partnern och för andra berättigade intressen, bland annat vårt intresse av att ha supernöjda kunder.
<br />
<br />För att skapa samlad statistik. Dina personuppgifter (t.ex. beställningsuppgifter) kan användas för att skapa statistik i aggregerad form, där uppgifterna inte kan identifiera dig. Statistik kan till exempel röra återkommande beställningar, problem och frågor. Detta görs med stöd av berättigade intressen, bland annat vårt intresse av att förbättra våra leveranser.
<br />
<br />För att följa lagar. Vi är skyldiga att följa svenska (och ibland andra) lagar. Det innebär att dina personuppgifter ibland kan behöva behandlas i den utsträckning som krävs enligt lag eller andra rättsliga förpliktelser, till exempel för rapportering till myndigheter.
<br />
<br />För att skydda Bzzt, dig och andra. Vi kan behöva behandla dina personuppgifter för att försvara eller göra gällande våra, dina eller andras juridiska rättigheter. Det kan till exempel behövas om du eller någon annan riktar krav mot oss (t.ex. reklamationer) eller för att utreda missbruk eller bedrägerier. Det är nödvändigt för att skydda dig och andra samt för andra berättigade intressen, bland annat vårt berättigade intresse av att kunna bemöta krav och reklamationer.
<br />
<br />Hur dina personuppgifter delas.
<br />Vi kommer aldrig sälja dina personuppgifter, men kan ibland behöva dela dem med andra pålitliga personer och företag. Exempelvis kan dina personuppgifter komma att delas med:
<br />
<br />Personer som jobbar hos oss. Dina personuppgifter kommer att delas med personer som jobbar hos Bzzt, men bara personer som behöver tillgång till dem för att kunna utföra sina jobb. Till exempel kommer vår support få ta del av dina personuppgifter för att kunna hjälpa dig på bästa sätt.
<br />
<br />Koncernbolag. Dina personuppgifter kan ibland behöva delas med bolag som ingår i samma koncern som Bzzt, till exempel för att vi ska kunna centralisera system och samarbeta sinsemellan.
<br />
<br />Bzzt:s underleverantörer. Dina personuppgifter kommer att delas med utvalda företag som hjälper oss att tillhandahålla våra tjänster och hemsidan. Sådana förtag utgörs av exempelvis leverantörer av IT och betaltjänster. Dessa företag får bara behandla dina personuppgifter enligt instruktioner från oss, eller för att uppfylla sina rättsliga skyldigheter. Vi jobbar bara med seriösa och noggrant utvalda leverantörer, så dina personuppgifter är i trygga händer.
<br />
<br />Myndigheter och rättsliga sammanhang. Dina personuppgifter kan ibland behöva delas med myndigheter, domstolar och andra parter. Till exempel om det krävs till följd av lag, i samband med en rättslig process eller för att besvara en begäran från någon myndighet.
<br />
<br />Företagsöverlåtelse. Det kan hända att vi, av strategiska eller affärsmässiga skäl, i framtiden beslutar att sälja eller överföra hela eller delar av Bzzt AB eller något av våra dotterbolag. Som ett led i en sådan transaktion kan dina personuppgifter behövas delas med parter som är involverade i processen.
<br />
<br />Övriga parter. Dina personuppgifter kan även delas med andra parter, om du ber oss eller tillåter oss att göra det.
<br />
<br />Dina uppgifter kan behandlas utanför EU/EES.
<br />
<br />Vi strävar alltid efter att behandla dina personuppgifter inom EU/EES. Dina personuppgifter kommer dock i några fall behöva överföras till företag utanför EU/EES. 
<br />
<br />Du bör känna till att andra regler kan gälla för dina personuppgifter utanför EU/EES, som ibland kan innebära ett sämre skydd. Vi kommer dock se till att rimliga legala, tekniska och organisatoriska åtgärder finns på plats för att säkerställa att dina personuppgifter hanteras säkert och med en adekvat skyddsnivå (t.ex. godkända standardklausuler). Du kan alltid kontakta oss om du har frågor kring tillämpliga skyddsåtgärder.
<br />
<br />Så länge sparas dina uppgifter.
<br />
<br />Vi sparar dina personuppgifter så kort tid som möjligt och bara så länge det är motiverat för syftet med behandlingen. Hur länge detta är kan bero på typen av uppgifter. Samma uppgift kan ibland sparas för olika ändamål. Till exempel kan en uppgift sparas för bokföring, trots att den har gallrats bort för andra syften.
<br />
<br />Leveransuppgifter sparas högst två år efter att leveransen bokades, men kan också sparas längre om du inte har betalat för leveransen. Personuppgifter som behandlas med stöd av ditt samtycke raderas när du återkallar ditt samtycke. Dina uppgifter kan ibland också behöva sparas en längre tid om det krävs enligt lag, till exempel lagar om beskattning och bokföring. Dina uppgifter kommer i så fall bara användas för att uppfylla dessa skyldigheter.
<br />
<br />Dina rättigheter.
<br />
<br />Det är dina personuppgifter. Du har därför rätt att påverka hur dina personuppgifter behandlas av oss. Här följer en kort sammanfattning av dina rättigheter.
<br />
<br />Rätt att invända mot behandling. Du har rätt att invända mot att dina personuppgifter behandlas för berättigade intressen. Det innebär att vi antingen måste bevisa att det finns tvingande berättigade skäl för behandlingen, som överväger dina intressen, eller sluta med behandlingen. Du kan alltid kontakta oss för att få mer information om den intresseavvägning som vi har gjort. Du har även rätt att invända mot att dina personuppgifter används för direktmarknadsföring (t.ex. nyhetsbrev). Du gör detta genom att avregistrera dig längst ner i det mail som du fått från oss.
<br />
<br />Rätt att få tillgång till och flytta din data. Du kan när som helst begära en kopia av dina personuppgifter och information om hur dessa har inhämtats, används, delas m.m. Du har ibland även rätt att överföra dina personuppgifter till en annan personuppgiftsansvarig, till exempel ett annat taxibolag eller leverantör.
<br />
<br />Rätt att radera uppgifter. Du har rätt att begära att en personuppgift om dig raderas om uppgiften inte längre är nödvändig för syftet som den samlades in eller på annat sätt behandlas för, eller om vi saknar laglig grund för att behandla uppgiften.
<br />
<br />Rätt att rätta felaktiga uppgifter. Du har rätt att begära att felaktiga personuppgifter rättas. Du har även rätt att komplettera ofullständiga personuppgifter.
<br />
<br />Rätt till begränsning. Du har rätt att begära att behandlingen av dina personuppgifter begränsas tills dess felaktiga uppgifter har rättats eller en invändning från dig har hanterats.
<br />
<br />Rätt att återkalla ditt samtycke. Du har rätt att när som helst återkalla ett samtycke som du har lämnat till oss. Notera dock att det inte påverkar den behandling som utförts innan återkallelsen.
<br />
<br />Du kan läsa mer om dina rättigheter på www.imy.se. Du kan kontakta oss på support@bzzt.se för att utöva dina rättigheter.
<br />
<br />Du bör känna till att en begränsning eller radering av dina personuppgifter kan påverka eller hindra din användning av våra tjänster. Det kan också finnas lagregler som ibland begränsar, eller utökar, dina rättigheter. Till exempel kan rättsliga skyldigheter hindra eller ge oss rätt att inte lämna ut, radera eller flytta delar av dina uppgifter.
<br />
<br />Klagomål.
<br />
<br />Du har rätt att kontakta och klaga hos Integritetsskyddsmyndigheten om du anser att dina personuppgifter har hanterats felaktigt av oss. Du kan läsa mer om detta på www.imy.se. Du kan också höra av dig till tillsynsmyndigheten i det land där du bor eller arbetar.
<br />
<br />Uppdateringar i policyn.
<br />
<br />Denna policy kan ibland behöva ändras eller uppdateras, till exempel om funktioner ändras eller läggs till i våra tjänster. Om några betydelsefulla ändringar görs kommer du informeras om detta på lämpligt sätt, för att ge dig möjlighet att ta ställning till ändringen innan den börjar gälla. Du uppmuntras även att läsa denna policy med jämna mellanrum. Mest så du inte missar eventuella ändringar som gjorts.
<br />
<br />Denna personuppgiftspolicy uppdaterades senast i november 2022.
        `,
    },
];

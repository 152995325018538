import { LetterCircle, LetterCircleChecked, Text, Svg } from '../icon.styles';

export const Large = (props: any) => {
    const { checked } = props;

    return (
        <Svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 570 570">
            {!checked && <LetterCircle cx="50%" cy="50%" r="50%" />}
            {checked && <LetterCircleChecked cx="50%" cy="50%" r="50%" />}
            <Text dy="73%" dx="33%">
                L
            </Text>
        </Svg>
    );
};

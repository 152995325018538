import styled from 'styled-components';
import { dimensions } from '../../dimensions';

export const Root = styled.section<{ slim: boolean; compact: boolean }>`
    display: grid;
    grid-template-rows: ${({ compact }) => (compact ? 'auto auto auto' : 'auto 1fr auto')};
    grid-template-columns: 1fr;
    flex: 1;
    ${({ slim }) => (slim ? 'max-width: 600px;' : '')};
    align-self: center;
    justify-self: center;
    width: ${({ slim }) => (slim ? '600px' : '85vw')};
    max-height: ${({ compact }) => (compact ? '60vh' : 'auto')};

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        width: 100vw;
        overflow-x: hidden;
        padding-top: calc(
            ${dimensions.mobileHeader} +
                ${({ compact }) => (compact ? '0px' : dimensions.mobileDisplaySelected)}
        );
    }

    @media print {
        padding-top: 0;
    }
`;
export const Top = styled.div`
    display: flex;

    @media print {
        margin-left: 50%;
        flex-direction: column;
        align-items: center;
        width: 50vw;
    }
`;
export const Content = styled.div`
    flex: 1;
    padding: 0 1rem 1rem 1rem;
    flex-grow: 1;
    align-items: flex-start;
`;
export const ActionRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        padding: 1rem;
    }

    padding-bottom: 70px;
`;

import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const Root = styled.div<{ marginTop?: string }>`
    flex: 1;
    background-color: ${colors.white};
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: ${({ marginTop }) => marginTop || '5rem'};

    @media print {
        display: flex;
        margin-bottom: 1em;
    }
`;

export const H2 = styled.h2<{ noPaddingBottom: boolean }>`
    padding-bottom: ${({ noPaddingBottom }) => (noPaddingBottom ? '0' : '15px')};

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        padding-bottom: ${({ noPaddingBottom }) => (noPaddingBottom ? '0' : '3px')};
    }

    @media print {
        font-size: 12pt; /* Adjust font size for print */
    }
`;

import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const Root = styled.div`
    & > * {
        margin-bottom: 20px;
    }

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const TimeButtonGrid = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }
`;

export const TimeButtonsLeft = styled.div<{ alignmentCondition?: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: ${({ alignmentCondition }) => (alignmentCondition ? 'center' : 'flex-end')};
    margin-right: 20px;
    justify-content: center;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        display: flex;
        flex-wrap: wrap;
        margin-right: 0px;
    }
`;
export const TimeButtonsRight = styled(TimeButtonsLeft)`
    font-family: sofia pro, sans-serif;
    align-items: flex-start;
    margin-right: 0px;
    margin-left: 20px;
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        margin-left: 0px;
        margin-right: 0px;
    }
`;

export const TimeButton = styled.div<{ selected?: boolean; active?: boolean }>`
    border: 3px solid ${colors.black};
    background-color: ${(props) => (props.selected ? colors.yellow : colors.white)};
    opacity: ${(props) => (props.active ? 1 : 0.6)};
    padding: 10px;
    cursor: ${(props) => (props.active ? 'pointer' : 'not-allowed')};
    width: 30vw;
    min-width: 11rem;
    max-width: 12rem;
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        width: 100%;
        padding: 5px;
        max-width: unset;
    }

    flex-direction: row-reverse;
    display: flex;
    margin-bottom: 20px;
`;

export const TimeButtonLeft = styled.div`
    flex-direction: column;
    display: flex;
    flex: 1;
    align-items: flex-end;
    font-family: sofia pro, sans-serif !important;
    font-size: 0.7rem;
    font-weight: 400;
`;
export const TimeButtonRight = styled.div`
    flex: 1;
    display: flex;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: Londrina Solid;
`;

export const PickersContainer = styled.div`
    @media (min-width: ${dimensions.mobileBreakpoint}) {
        max-width: 700px;
        margin-left: calc(50% - 350px);

        & > * {
            margin-bottom: 10px;
        }
        & input {
            width: 200px;
        }
    }
    & input {
        font-weight: unset;
    }
`;

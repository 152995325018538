import styled from 'styled-components';
import { dimensions } from '../../dimensions';

type Props = {
    fontWeight?: string;
    marginTop?: string;
};

export const Root = styled.div`
    display: grid;
    justify-content: center;
    gap: 15px;
    font-family: sofia pro, sans-serif !important;
    grid-template-columns: 1fr;
    width: 40vw;
    margin: 0 auto;

    @media (max-width: ${dimensions.tabletBreakpoint}) {
        margin-left: 60%;
    }
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    width: 100%;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 8px;
`;

export const Container = styled.div`
    padding-bottom: 10px;
`;

export const Name = styled.p<Props>`
    font-size: 0.75rem;
    padding-right: 10px;
    margin-bottom: 0px;
    margin-top: ${(props) => (props.theme.marginTop ? props.theme.marginTop : '0px')};
    font-weight: ${(props) => (props.theme.fontWeight ? props.theme.fontWeight : 'normal')};

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        font-size: 0.8rem;
    }

    @media print {
        font-size: 10pt; /* Adjust font size for print */
    }
`;

export const Value = styled.p<{ color?: string }>`
    font-size: 0.75rem;
    font-weight: normal;
    margin: 0px;
    text-align: left;
    ${(props) => (props.color ? `color: ${props.color};` : '')}

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        font-size: 0.8rem;
    }

    @media print {
        font-size: 10pt; /* Adjust font size for print */
    }
`;

export const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    width: min-content;
    align-items: flex-start;
    text-align: left;
    gap: 0 3px;

    // Remove right borderRadius from first button
    & > :first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    // Remove left borderRadius from second button
    & > :last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
`;

export const Logo = styled.img`
    display: none;
    width: 80px;
    cursor: pointer;
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        display: block;
        width: 60px;
        align-self: center;
    }
`;

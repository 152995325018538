import styled from 'styled-components';
import { colors } from '../../colors';
import { dimensions } from '../../dimensions';

export const Root = styled.header`
    background-color: ${colors.black};
    min-height: ${dimensions.header};
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 0 30px;
    width: 100%;
    z-index: 1000;
    position: fixed;

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        min-height: ${dimensions.mobileHeader};
    }

    @media print {
        display: none;
    }
`;

export const Nav = styled.nav`
    color: ${colors.white};
    display: flex;
    align-items: center;
    & > * {
        padding: 0 10px;
        &:last-child {
            margin-left: 50px;
            padding: 20px; // Hack to make the arrow smaller
        }
    }

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        display: none;
    }
`;

export const NavItem = styled.a<{ selected: boolean }>`
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: ${dimensions.mobileBreakpoint}) {
        border-top: 5px solid ${colors.black};
        text-align: left;
        align-items: center;
        font-size: 2rem;
        &:last-child {
            border-bottom: 5px solid ${colors.black};
        }
    }
`;

export const Status = styled.h3`
    color: ${colors.white};
    flex: 1;
    text-align: left;

    @media (min-width: ${dimensions.mobileBreakpoint}) {
        display: none;
    }
`;

export const MenuIcon = styled.div`
    color: ${colors.white};
    flex: 1;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @media (min-width: ${dimensions.mobileBreakpoint}) {
        display: none;
    }
`;

export const Logo = styled.img`
    width: 80px;
    cursor: pointer;
    @media (max-width: ${dimensions.mobileBreakpoint}) {
        width: 60px;
        align-self: center;
    }
`;

export const Line = styled.div<{ open: boolean }>`
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    transform-origin: 4px 0px;

    background: ${colors.white};
    border-radius: 3px;

    &:nth-child(1) {
        transform-origin: 0% 0%;
    }
    &:nth-child(3) {
        transform-origin: 0% 100%;
        margin-bottom: 0;
    }

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    ${({ open }) =>
        open &&
        `&:nth-child(1) {
            opacity: 1;
            transform: rotate(45deg) translate(-3px, 0px);
        }
        &:nth-child(2) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }
        &:nth-child(3) {
            opacity: 1;
            transform: rotate(-45deg) translate(-2px, 0px);
        }
    `};
`;

export const MobileMenu = styled.div`
    position: absolute;
    top: calc(${dimensions.mobileHeader});
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.lightyellow};
    cursor: pointer;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    color: ${colors.black};
    padding: 40px;
    @media (min-width: ${dimensions.mobileBreakpoint}) {
        display: none;
    }
    height: 100vh;

    @media print {
        display: none;
    }
`;

export const UserMenu = styled.div<{ top?: number; left?: number }>`
    position: absolute;
    top: calc(${({ top }) => top}px + 50px);
    left: calc(${({ left }) => left}px - 100px);
    background: ${colors.white};
    border: 1px solid ${colors.nearlywhite};
    border-radius: 5px;
    padding: 20px;
    z-index: 1000;
    width: 200px;

    @media screen and (max-width: ${dimensions.mobileBreakpoint}) {
        display: none;
    }
`;

export const UserMenuItem = styled.div<{ onClick?: () => void; hoverEnabled?: boolean }>`
    color: ${colors.black};
    font-size: 0.5rem;
    border-bottom: 1px solid ${colors.black};
    padding: 10px 0;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

    ${({ hoverEnabled }) => hoverEnabled && '&:hover { text-decoration: underline; }'}

    &:last-child {
        border-bottom: none;
    }
`;

export const UserMenuInvisibleWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
`;

export const colors = {
    // ---- Color Palette After 2023-09-28 ----
    darkIndicator: '#161F2A',
    black: '#1b1814',
    yellow: '#eab818',
    white: '#ffffff',
    nearlywhite: '#dddddd',
    lightyellow: '#ffecc2',
    verylightyellow: '#f3f2d6',
    lipstickred: '#8d1f30',
    orangishred: '#cc523f',
    lightorange: '#d4765c',
    forestgreen: '#567e4e',
    lightvoilet: '#898dc2',
    darkvoilet: '#2e2f60ff',
    purple: '#2e2f60',
    barneypurple: '#743d8b',
    lightbarneypurple: '#a979ae',
    pink: '#dc6d7e',
    grey: '#767676',
};

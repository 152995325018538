import { useCallback, useRef, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
    Line,
    Logo,
    MenuIcon,
    MobileMenu as MobileNav,
    Nav,
    NavItem as NavItemComponent,
    Root,
    Status,
    UserMenu,
    UserMenuItem,
    UserMenuInvisibleWrapper,
} from './header.styles';

type Route = {
    href: string;
    name: string;
};

import headerImg from './bzzt_logo.png';
import { Icon } from '../icon';
import { colors } from '../../colors';
import { BookingFlowStep } from '../../types/bzzt';
import { useUserStore } from '../../stores/useUserStore';
import { useBookingStore } from '../../stores/useBookingStore';

const routes: Route[] = [
    {
        href: BookingFlowStep.Delivery,
        name: 'Boka bud',
    },
    {
        href: '/bookings',
        name: 'Bokningar',
    },
    {
        href: '/contacts',
        name: 'Kontakter',
    },
];

function NavItem(props: any) {
    const { href, name, setOpen, ...rest } = props;
    const history = useHistory();
    const match = useRouteMatch<{ id?: string }>('');
    const selected = !!match;

    const handleClick = () => {
        history.push(href || '/');
        setOpen(false);
    };

    return <NavItemComponent {...rest} onClick={handleClick} selected={selected} />;
}

function Routes(props: any) {
    const { setOpen } = props;

    return (
        <>
            {routes.map((route: Route) => (
                <NavItem
                    key={route.href}
                    href={route.href}
                    name={route.name}
                    setOpen={() => setOpen(false)}
                >
                    {route.name}
                </NavItem>
            ))}
        </>
    );
}

function MobileMenuIcon({ open, ...props }: any) {
    return (
        <MenuIcon {...props}>
            <Line open={open} />
            <Line open={open} />
            <Line open={open} />
        </MenuIcon>
    );
}

export function Header() {
    const userMenuRef = useRef<HTMLDivElement | null>(null);
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [userMenu, setUserMenu] = useState(false);
    const userStore = useUserStore();
    const bookingStore = useBookingStore();

    const { offsetTop, offsetLeft } = userMenuRef?.current || { offsetTop: 0, offsetLeft: 0 };

    const closeUserMenu = useCallback(() => {
        if (userMenu) {
            setUserMenu(false);
        }
    }, [userMenu]);

    const handleLogoutClicked = () => {
        bookingStore.reset();
        userStore.logout();
        history.push('/login');
    };

    return (
        <>
            <Root>
                {!userStore.authenticated() && <Status>Inloggad</Status>}
                <Logo src={headerImg} onClick={() => history.push(BookingFlowStep.Delivery)} />
                {userStore.authenticated() && (
                    <>
                        <Nav>
                            <Routes
                                setOpen={() => setOpen(false)} // Can we remove this?
                            />
                            <div ref={userMenuRef}>
                                <Icon
                                    type="arrow"
                                    size="xs"
                                    fill={colors.white}
                                    onClick={() => setUserMenu(!userMenu)}
                                />
                            </div>
                        </Nav>
                        <MobileMenuIcon onClick={() => setOpen(!open)} open={open} />
                        {open && (
                            <MobileNav>
                                <Routes setOpen={() => setOpen(false)} />
                                <NavItemComponent onClick={handleLogoutClicked} selected={false}>
                                    Logga ut
                                </NavItemComponent>
                            </MobileNav>
                        )}
                        {userMenu && (
                            <UserMenuInvisibleWrapper onClick={closeUserMenu}>
                                <UserMenu top={offsetTop} left={offsetLeft}>
                                    <UserMenuItem>{userStore.config?.businessName}</UserMenuItem>
                                    <UserMenuItem onClick={handleLogoutClicked} hoverEnabled>
                                        Logga ut
                                    </UserMenuItem>
                                </UserMenu>
                            </UserMenuInvisibleWrapper>
                        )}
                    </>
                )}
            </Root>
        </>
    );
}

import { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Screen } from '../../structure/screen';
import { ItemTypeTranslation } from '../../utils';
import { Root, Container, Row, Value, Name, Logo } from './print.styles';
import { getBooking } from '../../utils/booking-action';
import { Bzzt } from '../../types';
import headerImg from '../../fragments/header/bzzt_logo.png';
import { Title } from '../../fragments/title';
import { ButtonRow } from '../../fragments/button-row';
import { Button, Variant } from '../../fragments/button';

interface LocationState {
    bookingCandidate: Bzzt.Booking;
}

interface PrintProps extends RouteComponentProps<{}, {}, LocationState> {}

export const Print: React.FC<PrintProps> = () => {
    const { id }: any = useParams();

    const [booking, setBooking] = useState<Bzzt.Booking | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isPrintOpen, setIsPrintOpen] = useState<boolean>(false);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [bookingCandidate, setBookingCandidate] = useState(null);

    useEffect(() => {
        if (params.data) {
            try {
                const parsedData = JSON.parse(decodeURIComponent(params.data));
                setBookingCandidate(parsedData);
            } catch (error) {
                console.error('Error parsing booking candidate:', error);
                // Handle the error, perhaps by providing a default value or showing an error message.
            }
        }
    }, [params.data]);

    const theme = {
        fontWeight: 'bold',
    };

    useEffect(() => {
        if (!booking) {
            // If BookingCandidate is provided, use it directly
            if (bookingCandidate) {
                setBooking(bookingCandidate);
            } else if (id) {
                const fetchBooking = async (reference: string) => {
                    setLoading(true);
                    try {
                        const { data, errorMessage } = await getBooking(reference);
                        if (errorMessage) {
                            // TODO: Handle error
                            setBooking(null);
                        } else if (data) {
                            setBooking(data);
                        }
                    } finally {
                        setLoading(false);
                    }
                };
                fetchBooking(id);
            }
        }
    }, [id, bookingCandidate, booking]);

    const handlePrint = () => {
        // Your print logic here
        window.print();
    };

    useEffect(() => {
        if (!loading && booking && !isPrintOpen) {
            // Loading is complete, and booking data is available
            handlePrint();
            setIsPrintOpen(true);
        }
    }, [loading, booking, isPrintOpen]);

    const handlePrintClicked = () => {
        window?.print();
    };

    return (
        <Screen
            slim={false}
            topContent={
                <>
                    <Logo src={headerImg} />
                    <Title marginTop={'1rem'} show>
                        Fraktsedel
                    </Title>
                </>
            }
            content={
                <Root>
                    <Container>
                        <Row>
                            <Name>Leveransdag:</Name>
                            <Value>
                                {new Date(
                                    booking?.dropoffTime || booking?.earliestDropoffTime || 0
                                ).toLocaleDateString()}
                            </Value>
                        </Row>
                        <Row>
                            <Name>Orderid/referens:</Name>
                            <Value>
                                {booking?.externalOrderId ??
                                    (booking as any)?.customerReference ??
                                    ''}
                            </Value>
                        </Row>
                        {booking &&
                            Object.entries(booking.items).map(([slug, item]) => {
                                const itemType =
                                    (item.type && ItemTypeTranslation[item.type]?.plural) || '';
                                const itemSize = item.size
                                    ? item.size.substring(0, 1).toUpperCase()
                                    : '';

                                return (
                                    <Row key={`item-${slug}`}>
                                        <Name>{`Antal ${itemType}:`}</Name>
                                        <Value>
                                            {itemSize} {item.count}
                                        </Value>
                                    </Row>
                                );
                            })}
                    </Container>
                    <Container>
                        <Row>
                            <Name theme={theme}>Hämtas:</Name>
                        </Row>
                        <Row>
                            <Name>Tid:</Name>
                            <Value>
                                {new Date(booking?.earliestPickupTime || 0).toLocaleTimeString([], {
                                    timeStyle: 'short',
                                })}{' '}
                                -{' '}
                                {new Date(booking?.latestPickupTime || 0).toLocaleTimeString([], {
                                    timeStyle: 'short',
                                })}
                            </Value>
                        </Row>
                        <Row>
                            <Name>Namn/Företag:</Name>
                            <Value>{booking?.pickup?.name ?? ''}</Value>
                        </Row>
                        <Row>
                            <Name>Telefonnummer:</Name>
                            <Value>{booking?.pickup?.phoneNumber ?? ''}</Value>
                        </Row>
                        <Row>
                            <Name>Adress:</Name>
                            <Value>{booking?.pickup?.address ?? ''}</Value>
                        </Row>
                        <Row>
                            <Name>Instruktioner:</Name>
                            <Value>{booking?.pickup?.instructions ?? ''}</Value>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Name theme={theme}>Lämnas:</Name>
                        </Row>
                        <Row>
                            <Name>Beräknad tid:</Name>
                            <Value>
                                {new Date(booking?.earliestDropoffTime || 0).toLocaleTimeString(
                                    [],
                                    {
                                        timeStyle: 'short',
                                    }
                                )}{' '}
                                -{' '}
                                {new Date(booking?.latestDropoffTime || 0).toLocaleTimeString([], {
                                    timeStyle: 'short',
                                })}
                            </Value>
                        </Row>
                        <Row>
                            <Name>Namn/företag:</Name>
                            <Value>{booking?.dropoff?.name ?? ''}</Value>
                        </Row>
                        <Row>
                            <Name>Telefonnummer:</Name>
                            <Value>{booking?.dropoff?.phoneNumber ?? ''}</Value>
                        </Row>
                        <Row>
                            <Name>Gatuadress:</Name>
                            <Value>{booking?.dropoff?.address ?? ''}</Value>
                        </Row>
                        <Row>
                            <Name>Instruktioner:</Name>
                            <Value>{booking?.dropoff?.instructions ?? ''}</Value>
                        </Row>
                    </Container>
                </Root>
            }
            actionRowContent={
                <ButtonRow>
                    <Button onClick={handlePrintClicked} variant={Variant.Print}>
                        Skriv ut
                    </Button>
                </ButtonRow>
            }
        />
    );
};

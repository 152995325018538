import { Bzzt } from '../types';

const usePrintHandling = () => {
    const handlePrintBooking = (data: Bzzt.BookingCandidate | string) => {
        // Convert the data to a string
        const serializedData = typeof data === 'string' ? data : JSON.stringify(data);

        // Generate the URL for the print route with the data parameter
        const printUrl = `/print?data=${encodeURIComponent(serializedData)}`;

        // Open a new tab with the print route
        const newTab = window.open(printUrl, '_blank');

        // Check if the new tab is opened successfully
        if (newTab) {
            // Optionally, focus on the new tab
            newTab.focus();
        } else {
            // Handle the case where the new tab couldn't be opened
            console.error('Unable to open a new tab for printing.');
        }
    };

    const handlePrintById = (id: string) => {
        // Generate the URL for the print route with the id parameter
        const printUrl = `/print/${id}`;

        // Open a new tab with the print route
        const newTab = window.open(printUrl, '_blank');

        // Check if the new tab is opened successfully
        if (newTab) {
            // Optionally, focus on the new tab
            newTab.focus();
        } else {
            // Handle the case where the new tab couldn't be opened
            console.error('Unable to open a new tab for printing.');
        }
    };

    return {
        handlePrintBooking,
        handlePrintById,
    };
};

export default usePrintHandling;

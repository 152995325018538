import { useHistory, RouteComponentProps, useLocation } from 'react-router-dom';
import { BackButton } from '../../fragments/back-button';
import { Button } from '../../fragments/button';
import { ButtonRow } from '../../fragments/button-row';
import { Title } from '../../fragments/title';
import { Screen } from '../../structure/screen';
import { Root, Container, Row, Name, Value, ButtonContainer } from './overview.styles';
import { ItemTypeTranslation } from '../../utils';
import { ProgressIndicator } from '../../fragments/progress-indicator';
import { InfoPopup } from '../../fragments/info-popup';
import { useEffect, useState } from 'react';
import { acceptDeliveryOffer, bookRide } from '../../utils/booking-action';
import { ERROR_MESSAGES } from '../../constants';
import useAnalytics, { AnalyticsEvent } from '../../hooks/useAnalytics';
import { BookingFlowStep } from '../../types/bzzt';
import {
    bookingCandidateItemsToParcelStringArray,
    bookingFlowAccessControl,
} from '../../utils/booking-action-utils';
import { useBookingStore } from '../../stores/useBookingStore';
import { Dropdown } from '../../fragments/dropdown';
import usePrintHandling from '../../hooks/usePrint';
import { useUserStore } from '../../stores/useUserStore';

interface RouteProps extends RouteComponentProps<{}>, React.Props<{}> {}

export const DeliveryBookingOverview = ({}: RouteProps) => {
    const history = useHistory();
    const location = useLocation();
    const analytics = useAnalytics();
    const bookingStore = useBookingStore();
    const userStore = useUserStore();
    const { handlePrintBooking } = usePrintHandling();
    const [error, setError] = useState<string>();

    const PRINT_OPTION = 'Bekräfta och skriv ut';

    useEffect(() => {
        if (!bookingStore.initialized) return;

        const redirectTo = bookingFlowAccessControl(location, bookingStore.bookingCandidate);
        if (redirectTo) {
            history.replace(redirectTo);
        }
    }, [location, history, bookingStore]);

    const handleBackClicked = () => {
        history.push(BookingFlowStep.DeliveryTime);
    };

    const handleConfirm = async () => {
        let referenceId: string | null = null;
        let bookingView: 'normal' | 'detailed' = 'normal';
        let deliveryOfferId: string | null = null;
        let localError: string | undefined = undefined;

        if (bookingStore.bookingCandidate.view === 'normal') {
            const { data, errorMessage } = await acceptDeliveryOffer(
                bookingStore.bookingCandidate.deliveryOfferId!
            );

            console.log({ data, errorMessage });

            if (errorMessage) {
                localError = errorMessage?.toLowerCase();
            } else if (data) {
                referenceId = data;
                bookingView = 'normal';
                deliveryOfferId = bookingStore.bookingCandidate.deliveryOfferId!;
            } else {
                localError = ERROR_MESSAGES.default;
            }
        } else {
            const { data, errorMessage } = await bookRide({
                type: 'delivery',
                from: bookingStore.bookingCandidate.pickup!.location!,
                to: bookingStore.bookingCandidate.dropoff!.location!,
                pickup: {
                    name: bookingStore.bookingCandidate.pickup!.name!,
                    address: bookingStore.bookingCandidate.pickup!.address!,
                    phonenumber: bookingStore.bookingCandidate.pickup!.phoneNumber!,
                    instructions: bookingStore.bookingCandidate.pickup!.instructions!,
                },
                dropoff: {
                    name: bookingStore.bookingCandidate.dropoff!.name!,
                    address: bookingStore.bookingCandidate.dropoff!.address!,
                    phonenumber: bookingStore.bookingCandidate.dropoff!.phoneNumber!,
                    instructions: bookingStore.bookingCandidate.dropoff!.instructions!,
                },
                external_order_id: bookingStore.bookingCandidate.customerReference!,
                parcels: bookingCandidateItemsToParcelStringArray(
                    bookingStore.bookingCandidate.items
                ),
                earliest_pickup_time: bookingStore.bookingCandidate.earliestPickupTime!,
                latest_pickup_time: bookingStore.bookingCandidate.latestPickupTime!,
                earliest_dropoff_time: bookingStore.bookingCandidate.earliestDropoffTime!,
                latest_dropoff_time: bookingStore.bookingCandidate.latestDropoffTime!,
            });

            if (errorMessage) {
                localError = errorMessage.toLowerCase();
            } else if (data) {
                referenceId = data;
                bookingView = 'detailed';
            } else {
                localError = ERROR_MESSAGES.default;
            }
        }

        if (localError) {
            setError(localError);
            return Promise.reject(localError); // Reject with the error
        }

        if (referenceId) {
            bookingStore.completeStepFive({ referenceId });
            void analytics.track({
                email: userStore?.email ?? '',
                event: AnalyticsEvent.BookingCreated,
                properties: {
                    booking_view: bookingView,
                    booking_reference: bookingStore.bookingCandidate.customerReference!,
                    offerId: deliveryOfferId,
                },
            });

            history.push(BookingFlowStep.DeliveryConfirmation);
        }
        return Promise.resolve();
    };

    const handleConfirmClicked = async () => {
        try {
            await handleConfirm(); // Wait for confirmation
        } catch (e) {
            console.error('handleConfirmClicked: An error occurred:', e);
        }
    };

    const handlePrintClicked = async () => {
        try {
            await handleConfirm(); // Wait for confirmation
            await handlePrintBooking(
                encodeURIComponent(JSON.stringify(bookingStore?.bookingCandidate))
            );
        } catch (e) {
            console.error('handlePrintClicked: An error occurred:', e);
        }
    };

    const theme = {
        fontWeight: 'bold',
    };

    const earliestPickupTime = new Date(bookingStore.bookingCandidate.earliestPickupTime || 0);
    const latestPickupTime = new Date(bookingStore.bookingCandidate.latestPickupTime || 0);
    const earliestDropoffTime = new Date(bookingStore.bookingCandidate.earliestDropoffTime || 0);
    const latestDropoffTime = new Date(bookingStore.bookingCandidate.latestDropoffTime || 0);

    return (
        <Screen
            slim={false}
            topContent={<Title marginTop={'1rem'}>Granska bokning</Title>}
            content={
                <Root>
                    <Container>
                        <Row>
                            <Name>Leveransdag:</Name>
                            <Value>
                                {earliestDropoffTime.toLocaleDateString([], {
                                    dateStyle: 'short',
                                })}
                            </Value>
                        </Row>
                        {bookingStore.bookingCandidate.items.map((item, index) => (
                            <Row key={index}>
                                <Name>
                                    {/* TODO: Use plural verison here */}
                                    {`Antal ${ItemTypeTranslation[item.type].plural} `}(
                                    {item.size.substring(0, 1).toUpperCase() || ''}) :
                                </Name>
                                <Value>{item.count || 1}</Value>
                            </Row>
                        ))}
                    </Container>
                    <Container>
                        <Row>
                            <Name>Orderid/Referens:</Name>
                            <Value>{bookingStore.bookingCandidate.customerReference}</Value>
                        </Row>
                        <Row>
                            <Name></Name>
                            <Value color="white">__</Value>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Name theme={theme}>Hämtas:</Name>
                        </Row>
                        <Row>
                            <Name>Tid:</Name>
                            <Value>
                                {earliestPickupTime.toLocaleTimeString([], {
                                    timeStyle: 'short',
                                })}{' '}
                                -{' '}
                                {latestPickupTime.toLocaleTimeString([], {
                                    timeStyle: 'short',
                                })}
                            </Value>
                        </Row>
                        <Row>
                            <Name>Namn/Företag:</Name>
                            <Value>{bookingStore.bookingCandidate?.pickup?.name || ''}</Value>
                        </Row>
                        <Row>
                            <Name>Telefonnummer:</Name>
                            <Value>{bookingStore.bookingCandidate.pickup?.phoneNumber || ''}</Value>
                        </Row>
                        <Row>
                            <Name>Gatuadress:</Name>
                            <Value>{bookingStore.bookingCandidate.pickup?.address || ''}</Value>
                        </Row>
                        <Row>
                            <Name>Instruktioner:</Name>
                            <Value>
                                {bookingStore.bookingCandidate.description || ''}{' '}
                                {bookingStore.bookingCandidate.pickup?.instructions || ''}
                            </Value>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Name theme={theme}>Lämnas:</Name>
                        </Row>
                        <Row>
                            <Name>Beräknad tid:</Name>
                            <Value>
                                {earliestDropoffTime.toLocaleTimeString([], {
                                    timeStyle: 'short',
                                })}{' '}
                                -{' '}
                                {latestDropoffTime.toLocaleTimeString([], {
                                    timeStyle: 'short',
                                })}
                            </Value>
                        </Row>
                        <Row>
                            <Name>Namn/företag:</Name>
                            <Value>{bookingStore.bookingCandidate.dropoff?.name || ''}</Value>
                        </Row>
                        <Row>
                            <Name>Telefonnummer:</Name>
                            <Value>
                                {bookingStore.bookingCandidate.dropoff?.phoneNumber || ''}
                            </Value>
                        </Row>
                        <Row>
                            <Name>Gatuadress:</Name>
                            <Value>{bookingStore.bookingCandidate.dropoff?.address || ''}</Value>
                        </Row>
                        <Row>
                            <Name>Instruktioner:</Name>
                            <Value>
                                {bookingStore.bookingCandidate?.dropoff?.instructions || ''}
                            </Value>
                        </Row>
                    </Container>
                    {error && <InfoPopup header={error} closePress={() => setError(undefined)} />}
                </Root>
            }
            actionRowContent={
                <ButtonRow>
                    <BackButton onClick={handleBackClicked} />
                    <ProgressIndicator currentStep={5} totalSteps={5} />
                    <ButtonContainer>
                        <Button onClick={handleConfirmClicked}>Bekräfta</Button>
                        <Dropdown
                            options={[PRINT_OPTION]}
                            onChange={(event) => {
                                if (event.target.value === PRINT_OPTION) {
                                    handlePrintClicked();
                                }
                            }}
                        />
                    </ButtonContainer>
                </ButtonRow>
            }
        />
    );
};

import { useHistory, useLocation } from 'react-router-dom';
import { Button, Variant } from '../../fragments/button';
import { Title } from '../../fragments/title';
import { Screen } from '../../structure/screen';
import { Root, Text, ButtonContainer } from './confirmation.styles';
import { useEffect, useState } from 'react';
import { BookingFlowStep } from '../../types/bzzt';
import { bookingFlowAccessControl } from '../../utils/booking-action-utils';
import { BookingState, useBookingStore } from '../../stores/useBookingStore';
import usePrintHandling from '../../hooks/usePrint';

export const Confirmation = () => {
    const history = useHistory();
    const location = useLocation();
    const { handlePrintBooking } = usePrintHandling();
    const [bookingStoreStateCopy, setBookingStoreStateCopy] =
        useState<Partial<BookingState> | null>(null);
    const bookingStore = useBookingStore();

    useEffect(() => {
        if (!bookingStore.initialized) return;
        if (bookingStoreStateCopy !== null) return;

        const redirectTo = bookingFlowAccessControl(location, bookingStore.bookingCandidate);
        if (!redirectTo) {
            setBookingStoreStateCopy({
                offerSearchWindow: bookingStore.offerSearchWindow,
                bookingCandidate: bookingStore.bookingCandidate,
            });
            bookingStore.reset();
        } else {
            history.replace(redirectTo);
        }
    }, [history, location, bookingStore, bookingStoreStateCopy]);

    const onSubmit = () => {
        history.push(BookingFlowStep.Delivery);
    };

    const onCopyClicked = () => {
        if (bookingStoreStateCopy) {
            bookingStore.setBookingStore(bookingStoreStateCopy);
            history.push(BookingFlowStep.Delivery);
        } else {
            console.error('unable to copy booking from storage');
        }
    };

    return (
        <Screen
            topContent={<Title marginTop={'1rem'}>Tack för din bokning!</Title>}
            content={
                <Root>
                    <Text>
                        Vi tar hand om resten!
                        <br />
                        Du kan kolla bokningens status
                        <br />
                        under bokningar.
                    </Text>
                </Root>
            }
            actionRowContent={
                <ButtonContainer>
                    <Button fillParent onClick={onCopyClicked}>
                        Skapa en kopia av ordern
                    </Button>
                    <Button fillParent onClick={onSubmit}>
                        Skapa en ny order
                    </Button>
                    <Button
                        fillParent
                        onClick={() =>
                            handlePrintBooking(
                                encodeURIComponent(
                                    JSON.stringify(bookingStoreStateCopy?.bookingCandidate)
                                )
                            )
                        }
                        variant={Variant.Print}
                    >
                        Skriv ut fraktsedel
                    </Button>
                </ButtonContainer>
            }
        />
    );
};
